import axios from "axios"
import Path from "./Path"

class ApiHelper {
    constructor() {
        // this.baseUrl = "http://localhost:5000"
        // this.baseUrl = "http://192.168.29.33:5000"
        this.baseUrl = "https://api.mesho.scriptscholer.in"


        axios.interceptors.request.use((config) => {
            config.headers["token"] = localStorage.getItem("token")
            return config
        }, (err) => {
            return Promise.reject(err)
        })

        axios.interceptors.response.use((res) => {
            return res
        }, (err) => {
            if (err && err.response && err.response.status === 401 && err.response.data.message === "unAuthorized") {
                localStorage.removeItem("token")
                window.location.pathname = Path.login
                return
            }
            return Promise.reject(err)
        })

    }

    listProduct() {
        return axios.get(this.baseUrl + "/product")
    }

    GetProductById(id) {
        return axios.get(this.baseUrl + "/product/" + id)
    }
    loginUser(data) {
        return axios.post(this.baseUrl + "/user/login", data)
    }
    registerUser(data) {
        return axios.post(this.baseUrl + "/user/register", data)
    }
    addtoCart(data) {
        return axios.post(this.baseUrl + "/cart", data)
    }
    listCart(user) {
        return axios.get(this.baseUrl + "/cart/" + user)
    }
    removeToCart(id) {
        return axios.delete(this.baseUrl + "/cart/" + id)
    }
    addAddress(data) {
        return axios.post(this.baseUrl + '/user/address', data)
    }
    listAddress(userId) {
        return axios.get(this.baseUrl + "/user/address/list/" + userId)
    }
    getAddressById(id) {
        return axios.get(this.baseUrl + "/user/address/" + id)
    }
    createOrder(data) {
        return axios.post(this.baseUrl + "/order", data)
    }
    FetchOrderById(id) {
        return axios.get(this.baseUrl + "/order/" + id)
    }
    verifyPeyment(data) {
        return axios.post(this.baseUrl + "/order/payment/verify", data)
    }
    EmptyUserCart(id) {
        return axios.delete(this.baseUrl + "/cart/empty/" + id)
    }
}

const apiHelper = new ApiHelper()
export default apiHelper